<template>
  <div class="provider-page py-5 blog-data">
    <b-container>
      <b-row>
        <b-col sm="12" md="10" lg="6">
          <img :src="require(`@/assets/${provider.logoURL}`)" :alt="provider.name" class="ml-o d-block  logo">
          <h1 class="mt-3 mb-0">{{ provider.name }}</h1>
          <star-rating :increment="0.5" :star-size="16" :show-rating="false" :read-only="true" :rating="provider.rating" class="mb-4"></star-rating>
          <router-view/>
        </b-col>
        <b-col sm="12" md="10" lg="6" class="px-lg-0">
            <GetStarted class="mt-5 mt-lg-0" :providerName="provider.name" :providerImgName="provider.logoURL"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import GetStarted from '@/components/GetStarted.vue'
import StarRating from 'vue-star-rating'

export default {
  components: {
    GetStarted,
    StarRating
  },
  props: {
    provider: {
      name: String,
      url: String,
      rating: Number,
      logoURL: String,
      preview: String
    }
  }
}
</script>

<style lang="scss" scoped>

.provider-page {
  text-align: left;

  .logo {
    width: 80%;
    max-width: 250px;
  }
}

</style>
